<template>
  <section>
    <pm-Breadcrumb :home="home" :model="items_bread" />

    <div class="row">
      <div class="col-md-10">
         <h2 class="titulo-links-escolha">Relatórios</h2>
      </div>
    </div>

    <div class="pm-card">
      <pm-TabView>
        <pm-TabPanel header="Informações">
            <pm-TabView>
              <pm-TabPanel header="Simplificados">
                <div class="p-fluid formgrid grid">
                  <div class="field col-12 md:col-6 lg:col-2" v-if="anoALL">
                    <label>Selecione o Ano:</label>
                    <select class="p-inputtext p-component"
                      style="appearance: revert !important;width: 100% !important;"
                      v-model="simples.ano" >
                        <option v-for="a in anoALL" :key="a.ano" :value="a.ano" >{{a.ano}}</option>
                    </select>
                  </div>

                  <div class="field col-12 md:col-8 lg:col-3">
                    <label>Selecione a Escola</label>
                    <select class="p-inputtext p-component" v-model="simples.escola_id" @change="buscarSegmentoALL();"
                      style="appearance: revert !important;width: 100% !important;" >
                        <option value="0" disabled selected> -- Escolha uma Escola -- </option>
                        <option v-for="n in escolaALL" :key="n" :value="n.id" >{{n.nome}}</option>
                    </select>
                  </div>

                  <div class="field col-12 md:col-12 lg:col-12"  v-if="segmentoALL.length>0">
                    <label>Selecione os Segmento</label>

                    <div style="display: flex;flex-wrap: wrap;">
                      <div class="form-check" style="margin-right: 5px;">
                        <input type="radio" class="form-check-input" v-model="todos_segmentos" value="1" checked style="margin-top: 13px;">
                        <label class="form-check-label" >Todos</label>
                      </div>
                      <div class="form-check">
                        <input type="radio" class="form-check-input" v-model="todos_segmentos" value="0" style="margin-top: 13px;">
                        <label class="form-check-label">Especifico</label>
                      </div>
                    </div>

                    <div class="row" v-if="todos_segmentos == 0" style="background-color: whitesmoke;padding: 14px;border-radius: 5px;">

                      <div class="col-md-4">
                        <pm-MultiSelect v-model="segmentos" :options="segmentoALL"
                                optionLabel="nome" optionGroupLabel="nome" optionGroupChildren="series" placeholder="SELECIONE OS SEGMENTOS" >
                        </pm-MultiSelect>
                      </div>
                    </div>
                  </div>

                  <div class="field col-12 md:col-12 lg:col-12" v-if="simples.escola_id != 0">
                    <button class="btn btn-primary" @click="buscarRelatorioALL()" style="margin-top:10px;">Buscar Informações</button>
                  </div>

                </div>
              </pm-TabPanel>
            </pm-TabView>
        </pm-TabPanel>
      </pm-TabView>
    </div>


  </section>
</template>

<script>
import { defineComponent } from "vue";
import axios from 'axios';
import { Escola } from "@/class/escolas";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Servidores } from "@/class/servidores.js";
import { Comunicacao } from "@/class/comunicacao";
import { Calendario } from "@/class/calendario";

export default defineComponent ({
  data(){
    return{
      home: {icon: 'pi pi-home', to: '/admin/painel-adm'},
      items_bread: [
        {label: 'Relatórios'},
      ],

      escolaALL:[],
      relatorioALL:[],
      segmentoALL: [],
      serieALL:[],
      anoAll:[],
      simples:{
        ano:2023,
        escola_id:0,
        segmento_id: 0,
      },
      segmentos:[],
      serie:{},
      qdt_seg:0,
      todos_segmentos: 1,
      colunas:{
        numero: true
      },
      imprimir:0,
      visivel:false,
    }
  },
  async beforeMount() {
    this.anosCalendario();
    this.buscarEscolaALL();
    this.buscarSegmentoALL();
  },
  methods: {
    async anosCalendario () {
      try {
        let dt = await Calendario.ano();
        this.anoALL = dt.data;
        this.anoALL.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
      } catch (e) {

      }
    },

    async buscarEscolaALL() {
      let data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },

    async buscarSegmentoALL() {
      this.segmentoALL = [];
      this.serie = [];
      this.qdt_seg =0;

      let data = await SegmentoEscolar.obtemTodos();

      for (const it of data.data) {
        let dt = await SegmentoEscolar.listarSegmentosPorSerie(it.id);
        let se = [];
        for (const ite of dt.data) {
          let n = {
            nome : ite.nome,
            id: ite.id,
            segmento_id: it.id
          };
          se.push(n);
        }

        it.series = se;
        this.segmentoALL.push(it)
      }
    },

    async buscarRelatorioALL() {
      this.imprimir = 0;
      this.relatorioALL = [];

      let data = await axios.post('/relatorio',{
        ano: this.simples.ano,
        escola_id: this.simples.escola_id,
        segmentos: this.segmentos,
        series: this.serie,
      });

      let arr = [];
      for (const it of data.data) {
        let alunos_arr=[];
        alunos_arr.push(it.alunos);

        for (const key in alunos_arr) {
          arr.push(alunos_arr[key]);
        }
      }

      for (let index = 0; index < arr.length; index++) {
        for (let j = 0; j < arr[index].length; j++) {
          this.relatorioALL.push(arr[index][j]);
        }
      }

      var mywindow = window.open('', 'PRINT', 'height=auto,width=800');
      mywindow.document.write(`<html><head> <title> Relatorio ${this.relatorioALL[0]['ano']} </title>`);
        mywindow.document.write(`
        <style>
          body {
              margin: 0;
              color: #212529;
              text-align: center;
              background-color: #fff;
          }
          table {
              border-collapse: collapse;
          }
          table tr td, table tr th{
            font-size: 12px !important;
          }
          thead {
            display: table-header-group;
            vertical-align: middle;
            border-color: inherit;
          }
          tr {
            display: table-row;
            vertical-align: inherit;
            border-color: inherit;
          }
          .table-bordered {
              border: 1px solid #dee2e6;
          }
          .table {
              width: 100%;
              margin-bottom: 1rem;
              color: #212529;
          }
          .table-bordered thead td, .table-bordered thead th {
              border-bottom-width: 2px;
          }
          .table thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
          }
          .table-bordered td, .table-bordered th {
              border: 1px solid #dee2e6;
          }
          .table td, .table th {
            padding: 0.75rem;
          }
        </style>
        `);
      mywindow.document.write('</head><body >');
      mywindow.document.write('<table class="table table-bordered">');

      mywindow.document.write(`
        <thead>
          <tr>
            <th> N° </th>
            <th> TURMA / TURNO </th>
            <th> ALUNOS POR TURMA </th>
            <th> ALUNO(A) </th>
            <th> MÃE E PAI </th>
            <th> NASCIMENTO </th>
            <th> NATURALIDADE </th>
            <th> SEXO </th>
            <th> COR </th>
            <th> CPF </th>
            <th> ENDEREÇO </th>
            <th> PNE </th>
          </tr>
        </thead>`
      );
      for (let i= 0; i < this.relatorioALL.length; i++) {
        mywindow.document.write(`
          <tbody>
            <tr>
              <td>${i+1}</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['turma_nome']  +'<br><br>'+ this.relatorioALL[i]['turno'] }</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['quantidade_alunos'] }</td>
              <td>${ this.relatorioALL[i]['aluno']['nome']  +' '+  this.relatorioALL[i]['aluno']['sobrenome'] }</td>
              <td>${ this.relatorioALL[i]['aluno']['nome_mae']  +'<br><br>'+  this.relatorioALL[i]['aluno']['nome_pai'] }</td>
              <td style="text-align:center;">${ this.relatorioALL[i]['aluno']['nascimento_formatado'] }</td>
              `);

              if(this.relatorioALL[i]['aluno']['naturalidade']){
                 mywindow.document.write(`<td style="text-align:center;">${ this.relatorioALL[i]['aluno']['naturalidade'] }</td>`);
              }else{
                mywindow.document.write(`
                  <td></td>`);
              }

              if(this.relatorioALL[i]['aluno']['sexo']){
                 mywindow.document.write(`<td style="text-align:center;">${ this.relatorioALL[i]['aluno']['sexo'] }</td>`);
              }else{
                mywindow.document.write(`<td></td>`);
              }

              if(this.relatorioALL[i]['aluno']['cor_raca']){
                 mywindow.document.write(`<td style="text-align:center;">${ this.relatorioALL[i]['aluno']['cor_raca'] }</td>`);
              }else{
                mywindow.document.write(`<td></td>`);
              }

              if(this.relatorioALL[i]['aluno']['cpf']){
                mywindow.document.write(`
                <td style="text-align:center;">${ this.relatorioALL[i]['aluno']['cpf'] }</td>`);
              }else{
                mywindow.document.write(`
                <td></td>`);
              }


              if(this.relatorioALL[i]['aluno']['enderecos']){
                mywindow.document.write(`
                <td style="text-align:center; text-transform: uppercase;">
                   ${ this.relatorioALL[i]['aluno']['enderecos']["logradouro"] }
                   ${ this.relatorioALL[i]['aluno']['enderecos']["numero"] }
                   ${ this.relatorioALL[i]['aluno']['enderecos']["bairro"] }
                   ${ this.relatorioALL[i]['aluno']['enderecos']["cidade"] }
                   ${ this.relatorioALL[i]['aluno']['enderecos']["estado"] }
                </td>`);
              }else{
                mywindow.document.write(`
                <td></td>`);
              }


              if(this.relatorioALL[i]['aluno']['nee']){
                mywindow.document.write(`
                  <td style="text-align:center;">Sim</td>`);
              }else{
                mywindow.document.write(`
                  <td style="text-align:center;">Não</td>`);
              }

              mywindow.document.write(`
            </tr>
          </tbody>`
        );

      }

      mywindow.document.write('</table>');
      //mywindow.document.write(document.getElementById('tabela_id').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus();

      mywindow.print();
      mywindow.close();

      //this.visivel = true;
      //this.imprimir = 1;
    },
  }
});
</script>

<style>

</style>
